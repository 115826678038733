import React from "react";
import { graphql, navigate } from "gatsby";
import Img from "gatsby-image";
import Layout from "./../components/Layout";
import { H1 } from "./../components/Typography";
import ArticleBody from "./../components/ArticleBody";
import { BigButton, SmallButton } from "./../components/Buttons";
import ArrowLeft from "./../components/icons/ArrowLeft";
import { getDate } from "./../modules/time";
import Download from "./../components/icons/Download";
import {
  PageWrapperDiv,
  HeaderImageWrapper,
} from "./../components/PressStyles/elements";

const PressReleasePage = ({ data, pageContext }) => {
  // console.log(data, pageContext);
  const {
    title,
    headerImage,
    jobShortDescription,
    editorialDate,
    pressReleaseDownload,
    richContent,
    callToAction,
  } = data.pressReleaseData;
  const thisHeaderImage = headerImage.length
    ? headerImage
    : data.genericHeaderImage.headerImage;
  return (
    <Layout
      backgroundColor={"var(--white)"}
      footerColor={"var(--red)"}
      skipHeader
      title={title}
      SEODescription={jobShortDescription}
    >
      <PageWrapperDiv>
        <HeaderImageWrapper>
          <Img fixed={thisHeaderImage[0].wideImage.childImageSharp.fixed} />
          <H1>{title}</H1>
        </HeaderImageWrapper>
        <ArticleBody
          topline={getDate(editorialDate)}
          subTitle={jobShortDescription}
          subTitleColor={"var(--black)"}
          leftColumn={
            <SmallButton
              onClick={() => {
                navigate(`/press/`);
              }}
            >
              <ArrowLeft />
              Back to overview
            </SmallButton>
          }
          mainColumn={richContent}
          color={"var(--red)"}
          textColor={"var(--black)"}
        />
        <p
          style={{
            textAlign: "center",
            margin: "50px auto",
            display: "flex",
            justifyContent: "center",
          }}
        >
          {null && (
            <a href={pressReleaseDownload[0].url} download>
              <BigButton color={"var(--black)"}>
                <Download color={"var(--black)"} />
                {callToAction || "Download press kit"}
              </BigButton>
            </a>
          )}
        </p>
      </PageWrapperDiv>
    </Layout>
  );
};

export default PressReleasePage;

export const pageQuery = graphql`
  query($id: String!) {
    genericHeaderImage: craftPressPressEntry {
      headerImage {
        ... on Craft_editorialAssets_Asset {
          wideImage: localFile {
            publicURL
            childImageSharp {
              fixed(width: 1440) {
                src
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
      }
    }
    pressReleaseData: craftEntryInterface(id: { eq: $id }) {
      ... on Craft_pressRelease_pressRelease_Entry {
        title
        jobShortDescription
        editorialDate
        callToAction
        pressReleaseDownload {
          url
        }
        headerImage {
          ... on Craft_editorialAssets_Asset {
            wideImage: localFile {
              publicURL
              childImageSharp {
                fixed(width: 1440, height: 610, fit: COVER) {
                  src
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
        }
        richContent {
          ... on Craft_richContent_matterportEmbed_BlockType {
            typeHandle
            matterportUrl
          }
          ... on Craft_richContent_podiantPlayer_BlockType {
            podiantUrl
            typeHandle
          }
          ... on Craft_richContent_anghamiPlayer_BlockType {
            anghamiUrl
            typeHandle
          }
          ... on Craft_richContent_soundcloudPlayer_BlockType {
            soundcloudUrl
            typeHandle
          }
          ... on Craft_richContent_spotifyPlayer_BlockType {
            spotifyUrl
            typeHandle
          }
          ... on Craft_richContent_image_BlockType {
            id
            typeHandle
            imageWithCaptionImage {
              ... on Craft_communityAssets_Asset {
                slide: localFile {
                  publicURL
                  childImageSharp {
                    fixed(width: 715, fit: COVER) {
                      src
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
              ... on Craft_eventsAssets_Asset {
                slide: localFile {
                  publicURL
                  childImageSharp {
                    fixed(width: 715, fit: COVER) {
                      src
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
              ... on Craft_editorialAssets_Asset {
                slide: localFile {
                  publicURL
                  childImageSharp {
                    fixed(width: 715, fit: COVER) {
                      src
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
            }
            imageWithCaptionCaption
          }
          ... on Craft_richContent_slideShow_BlockType {
            id
            typeHandle
            images {
              ... on Craft_communityAssets_Asset {
                slide: localFile {
                  publicURL
                  childImageSharp {
                    fixed(height: 460, fit: COVER) {
                      src
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
              ... on Craft_eventsAssets_Asset {
                slide: localFile {
                  publicURL
                  childImageSharp {
                    fixed(height: 460, fit: COVER) {
                      src
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
              ... on Craft_editorialAssets_Asset {
                slide: localFile {
                  publicURL
                  childImageSharp {
                    fixed(height: 460, fit: COVER) {
                      src
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
            }
            carouselCaption
          }
          ... on Craft_richContent_richText_BlockType {
            id
            typeHandle
            richText
          }
          ... on Craft_richContent_imageSlider_BlockType {
            typeHandle
            imageSliderImages {
              ... on Craft_communityAssets_Asset {
                slide: localFile {
                  publicURL
                  childImageSharp {
                    fixed(height: 460, fit: COVER) {
                      src
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
              ... on Craft_eventsAssets_Asset {
                slide: localFile {
                  publicURL
                  childImageSharp {
                    fixed(height: 460, fit: COVER) {
                      src
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
              ... on Craft_editorialAssets_Asset {
                slide: localFile {
                  publicURL
                  childImageSharp {
                    fixed(height: 460, fit: COVER) {
                      src
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
            }
            imageSliderCaptions {
              captionLine4
              captionLine3
              captionLine2
              captionLine1
            }
          }
          ... on Craft_richContent_videoWithCaption_BlockType {
            typeHandle
            videoWithCaptionCaption
            videoWithCaptionVideoOnTheLeft
            videoWithCaptionUseCredit
            videoWithCaptionUrl
            videoWithCaptionVideo {
              url
            }
          }
          ... on Craft_richContent_photoWithQuote_BlockType {
            typeHandle
            photoWithQuoteImage {
              ... on Craft_communityAssets_Asset {
                slide: localFile {
                  publicURL
                  childImageSharp {
                    fixed(height: 460, fit: COVER) {
                      src
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
              ... on Craft_eventsAssets_Asset {
                slide: localFile {
                  publicURL
                  childImageSharp {
                    fixed(height: 460, fit: COVER) {
                      src
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
              ... on Craft_editorialAssets_Asset {
                slide: localFile {
                  publicURL
                  childImageSharp {
                    fixed(height: 460, fit: COVER) {
                      src
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
            }
            photoWithQuoteImageOnTheLeft
            photoWithQuoteImageQuote
            photoWithQuoteImageQuoteAttribution
          }
        }
      }
    }
  }
`;
